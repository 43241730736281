import React, { useState } from 'react'
import { inject, observer } from 'mobx-react'
import { add } from 'ramda'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import CoveringRecap from './CoveringRecap'

import PropertyEmbellishmentCtrl from 'stores/Mission/EasyEstimation/PropertyEmbellishmentCtrl'
import CoveringForm from './CoveringForm'
import Unit from 'pages/EasyEstimation/components/Unit'
import { euro } from 'utils/currency'

const Covering = ({
  store,
  rcpData,
  rcpPackages,
  isClosed,
  rcpData: { id, groupId, part, area, quantity, percentageDammaged, covering },
  packageData,
  closeNewForm,
  CartStore: { changeGroupIdPackages, save },
}) => {
  const [showEditForm, setShowEditForm] = useState(false)
  const { t } = useTranslation()
  const { editCoveringForm, editCovering, deleteCovering, setProperty } = PropertyEmbellishmentCtrl

  if (showEditForm && editCoveringForm === groupId) {
    return (
      <CoveringForm
        mode="edit"
        store={store}
        show={showEditForm}
        closeForm={() => {
          save()
          setShowEditForm(false)
          setProperty('editCoveringForm', null)
        }}
      />
    )
  }

  return (
    <div className="row no-gutters align-items-center rounded p-2 mb-2" key={`rcp-${id}`}>
      <div className="col-5">
        <span className="font-weight-bold">{t(`mission.easyEstimation.parts.${part}`)}</span>
        <br />
        <Unit
          part={part}
          unitByDefault={rcpData.unitByDefault}
          area={area}
          quantity={quantity}
        /> - {percentageDammaged}% endommagé - {covering.label}
      </div>

      <div className="col text-right">
        <button
          className={classNames('btn btn-outline-primary', {
            'selected-price': !packageData.selfRepair,
            'disabled-price': packageData.selfRepair,
          })}
          disabled={isClosed}
          onClick={() => {
            if (packageData.selfRepair) changeGroupIdPackages(groupId, 'selfRepair', false)
          }}
        >
          {euro(rcpPackages.reduce((acc, data) => add(acc, data.finalPriceCatalog), 0))} TTC
        </button>
      </div>

      <div className="col text-right">
        <button
          className={classNames('btn btn-outline-primary', {
            'selected-price': packageData.selfRepair,
            'disabled-price': !packageData.selfRepair,
          })}
          disabled={isClosed}
          onClick={() => {
            if (!packageData.selfRepair) changeGroupIdPackages(groupId, 'selfRepair', true)
          }}
        >
          {euro(rcpPackages.reduce((acc, data) => add(acc, data.finalPriceSelfRepair), 0))} TTC
        </button>
      </div>

      <div className="col d-flex justify-content-end align-items-center">
        <CoveringRecap isClosed={isClosed} rcpData={rcpData} rcpPackages={rcpPackages} />

        {!isClosed && (
          <button
            className="btn btn-outline-primary rounded-circle mr-1"
            onClick={() => {
              closeNewForm()
              editCovering(rcpData, rcpPackages)
              setShowEditForm(true)
            }}
          >
            <i className="fa fa-edit" />
          </button>
        )}

        {!isClosed && (
          <button
            className="btn btn-outline-primary rounded-circle"
            onClick={() => deleteCovering(groupId)}
          >
            <i className="fa fa-trash" />
          </button>
        )}
      </div>
    </div>
  )
}

export default inject('CartStore')(observer(Covering))
