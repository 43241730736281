import React from 'react'
import { inject, observer } from 'mobx-react'
import { NavLink } from 'react-router-dom'
import { compose } from 'recompose'

import './Header.css'
import { getTheme } from 'utils'
import MobileMenu from './MobileMenu'
import DesktopMenu from './DesktopMenu'
import OfflineBar from './OfflineBar'

let isMatmut = getTheme() === 'matmut'
let isCardif = getTheme() === 'cardif'

let logo = null
let customLogo = false
if (isMatmut) {
  logo = require('assets/expertis-logo.svg')
  customLogo = true
} else if (isCardif) {
  logo = require('assets/cardif-logo.svg')
  customLogo = true
} else if (process.env.REACT_APP_LOGO_SUFFIX) {
  logo = require(`assets/solera-logo-${process.env.REACT_APP_LOGO_SUFFIX}.png`)
  customLogo = true
} else {
  logo = require('assets/sps-logo.svg')
}

const Header = ({
  UiCtrl: { language, deviceType, hasDebugToolbar, isOffLine, setProperty, displayDebugToolbar },
  UserStore,
  changeLanguage,
}) => {
  const onChangeLanguage = lng => {
    if (lng !== language) changeLanguage(lng)
  }

  const showDebugToolbar = e => {
    e.preventDefault()
    e.stopPropagation()
    setProperty('displayDebugToolbar', !displayDebugToolbar)
  }

  const stopImpersonating = () => {
    UserStore.setProperty('impersonating', null)
    UserStore.loadImpersonatedUser()
  }

  if (UserStore.customer) {
    if (UserStore.customer.theme) {
      document.body.classList.add(UserStore.customer.theme)
    }
  }

  if (UserStore.customer && customLogo === false) {
    try {
      let customerLogo = require('assets/' + UserStore.customer.id + '.svg')
      if (customerLogo) logo = customerLogo
    } catch (e) {}
  }
  return (
    <nav className="navbar navbar-expand-lg navbar-light fixed-top d-flex flex-column p-0">
      <div className="container">
        <NavLink exact className="ml-2 ml-lg-0 text-primary" to="/">
          <img src={logo} alt="Logo SPS" className="navbar-brand logo-sps" />
          {hasDebugToolbar && (
            <span className="btn btn-info clickable" onClick={showDebugToolbar}>
              <i className="fa fa-bug" />
            </span>
          )}
          {UserStore.impersonating && (
            <span
              data-toggle="tooltip"
              title="Quit impersonation mode"
              style={{ marginLeft: '10px', color: 'white' }}
              className="btn btn-warning clickable"
              onClick={stopImpersonating}
            >
              <i className="fa fa-user-secret" />
            </span>
          )}
        </NavLink>
        {deviceType === 'mobile' ? (
          <MobileMenu onChangeLanguage={onChangeLanguage} />
        ) : (
          <DesktopMenu onChangeLanguage={onChangeLanguage} />
        )}
      </div>
      {isOffLine ? <OfflineBar /> : null}
    </nav>
  )
}

export default compose(inject('UiCtrl', 'UserStore'), observer)(Header)
