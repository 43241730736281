import React from 'react'
import { inject, observer } from 'mobx-react'
import InputPrice from 'components/InputPrice/InputPrice'
import Franchise from './Franchise'
import { euro } from 'utils/currency'
import TotalPriceWarning from './TotalPriceWarning'

const Settlements = ({
  CartStore: {
    instructions: { ren, selfRepair, relatedCost, negotiation },
    setInstructions,
    totalSelfRepair,
    totalPackagesWithRen,
    totalFurniturePrice,
    leakTotalPriceCatalog,
    totalRelatedCostsAnnexPriceWithVAT,
    leakDeffered,
    leakImmediate,
    totalColumnDeffered,
    leakTotal,
    totalColumnTotal,
    selfRepairImmediate,
    totalPropertyAndEmbellishment,
    propertyAndEmbellishmentImmediate,
    furnitureImmediate,
    relatedCostImmediate,
    relatedCostDeffered,
    totalColumnImmediate,
    propertyAndEmbellishmentDeffered,
    renDeffered,
    relatedCostTotal,
    hasTravelRCPPackage,
    travelRCPPackage,
    configuration: { travelPackageManageable = false },
    compensationMinPrice,
  },
}) => {
  const onChange = e => {
    setInstructions(e.target.name, e.target.value === '' ? 0 : parseFloat(e.target.value), true)
  }

  return (
    <React.Fragment>
      <table className="table table-bordered d-none d-md-table mt-5">
        <tbody>
          <tr>
            <th className="text-center align-middle">Modalité de Règlement</th>
            <th className="text-center align-middle">Réglement immédiat TTC</th>
            <th className="text-center align-middle">Réglement différé TTC</th>
            {/*<th className="text-center align-middle">Forfait compensatoire TTC</th>*/}
            <th className="text-center align-middle">Total réglement TTC</th>
            {/*<th className="text-center align-middle">Montant TTC du recours à présenter</th>*/}
          </tr>

          {/* SELF REPAIR */}
          <tr>
            <td className="text-center align-middle">GAG - Auto-réparation</td>
            <td className="text-center align-middle">
              <InputPrice
                name="selfRepair.immediate"
                price={selfRepairImmediate}
                onChange={onChange}
                width={80}
              />
            </td>
            <td className="text-center align-middle">
              <InputPrice
                name="selfRepair.deffered"
                price={selfRepair.deffered}
                onChange={onChange}
                width={80}
              />
            </td>
            <td className="text-center align-middle">{euro(totalSelfRepair)}</td>
            {/* <CompensatoryPackage type="selfRepair" trueTotal={totalSelfRepair} /> */}
            {/*<td className="text-center align-middle table-active"></td>*/}
          </tr>

          {/* REN */}
          <tr>
            <td className="text-center align-middle">Réparation en Nature</td>
            <td className="text-center align-middle">
              <InputPrice
                name="ren.immediate"
                price={ren.immediate}
                onChange={onChange}
                width={80}
              />
            </td>
            <td className="text-center align-middle">
              <InputPrice name="ren.deffered" price={renDeffered} onChange={onChange} width={80} />
            </td>
            <td className="text-center align-middle">{euro(totalPackagesWithRen)}</td>
            {/* <CompensatoryPackage type="ren" trueTotal={totalPackagesWithRen} /> */}
            {/*<td className="text-center align-middle">*/}
            {/*  <InputPrice*/}
            {/*    name="ren.presented"*/}
            {/*    price={ren.presented}*/}
            {/*    onChange={onChange}*/}
            {/*    width={80}*/}
            {/*  />*/}
            {/*</td>*/}
          </tr>

          {/* PROPERTY + EMBELLISHMENT */}
          <tr>
            <td className="text-center align-middle">Indémnités pécuniaires</td>
            <td className="text-center align-middle">
              <InputPrice
                name="propertyAndEmbellishment.immediate"
                price={propertyAndEmbellishmentImmediate}
                onChange={onChange}
                width={80}
              />
            </td>
            <td className="text-center align-middle">
              <InputPrice
                name="propertyAndEmbellishment.deffered"
                price={propertyAndEmbellishmentDeffered}
                onChange={onChange}
                width={80}
              />
            </td>
            <td className="text-center align-middle">{euro(totalPropertyAndEmbellishment)}</td>
            {/* <CompensatoryPackage
              type="propertyAndEmbellishment"
              trueTotal={totalPropertyAndEmbellishment}
            /> */}
            {/*<td className="text-center align-middle">*/}
            {/*  <InputPrice*/}
            {/*    name="propertyAndEmbellishment.presented"*/}
            {/*    price={propertyAndEmbellishment.presented}*/}
            {/*    onChange={onChange}*/}
            {/*    width={80}*/}
            {/*  />*/}
            {/*</td>*/}
          </tr>

          {(compensationMinPrice.immediate > 0 || compensationMinPrice.deffered > 0) && (
            <tr>
              <td className="text-center align-middle">Complément Forfait minimum</td>
              <td className="text-center align-middle pl-5">
                {euro(compensationMinPrice.immediate)}
              </td>
              <td className="text-center align-middle pl-5">
                {euro(compensationMinPrice.deffered)}
              </td>
              <td className="text-center align-middle">
                {euro(compensationMinPrice.immediate + compensationMinPrice.deffered)}
              </td>
            </tr>
          )}

          {/* FURNITURE */}
          <tr>
            <td className="text-center align-middle">Mobilier</td>
            <td className="text-center align-middle">
              <InputPrice
                name="furniture.immediate"
                price={furnitureImmediate}
                onChange={onChange}
                width={80}
              />
            </td>
            <td className="text-center align-middle table-active"></td>
            {/*<td className="text-center align-middle table-active"></td>*/}
            <td className="text-center align-middle">
              <TotalPriceWarning total={furnitureImmediate} trueTotel={totalFurniturePrice} />
            </td>
            {/*<td className="text-center align-middle">*/}
            {/*  <InputPrice*/}
            {/*    name="furniture.presented"*/}
            {/*    price={furniture.presented}*/}
            {/*    onChange={onChange}*/}
            {/*    width={80}*/}
            {/*  />*/}
            {/*</td>*/}
          </tr>

          {/* LEAK */}
          <tr>
            <td className="text-center align-middle">Recherche de fuite</td>
            <td className="text-center align-middle">
              <InputPrice
                name="leak.immediate"
                price={leakImmediate}
                onChange={onChange}
                width={80}
              />
            </td>
            <td className="text-center align-middle">
              <InputPrice
                name="leak.deffered"
                price={leakDeffered}
                onChange={onChange}
                width={80}
              />
            </td>
            {/*<td className="text-center align-middle table-active"></td>*/}
            <td className="text-center align-middle">
              <TotalPriceWarning total={leakTotal} trueTotal={leakTotalPriceCatalog} />
            </td>
            {/*<td className="text-center align-middle">*/}
            {/*  <InputPrice*/}
            {/*    name="leak.presented"*/}
            {/*    price={leak.presented}*/}
            {/*    onChange={onChange}*/}
            {/*    width={80}*/}
            {/*  />*/}
            {/*</td>*/}
          </tr>

          {/* RELATED COST */}
          <tr>
            <td className="text-center align-middle">Frais annexes</td>
            <td className="text-center align-middle">
              <InputPrice
                name="relatedCost.immediate"
                price={relatedCostImmediate}
                onChange={onChange}
                width={80}
              />
            </td>
            <td className="text-center align-middle">
              <InputPrice
                name="relatedCost.deffered"
                price={relatedCostDeffered}
                onChange={onChange}
                width={80}
              />
            </td>
            {/*<td className="text-center align-middle table-active"></td>*/}
            <td className="text-center align-middle">
              <TotalPriceWarning
                total={relatedCostTotal}
                trueTotal={totalRelatedCostsAnnexPriceWithVAT}
              />
            </td>
            {/*<td className="text-center align-middle">*/}
            {/*  <InputPrice*/}
            {/*    name="relatedCost.presented"*/}
            {/*    price={relatedCost.presented}*/}
            {/*    onChange={onChange}*/}
            {/*    width={80}*/}
            {/*  />*/}
            {/*</td>*/}
          </tr>

          {/* NEGOTIATION */}
          <tr>
            <td className="text-center align-middle">Négociation</td>
            <td className="text-center align-middle">
              <InputPrice
                name="negotiation.immediate"
                price={negotiation.immediate}
                onChange={onChange}
                width={80}
              />
            </td>
            <td className="text-center align-middle table-active"></td>
            {/*<td className="text-center align-middle table-active"></td>*/}
            <td className="text-center align-middle">{euro(negotiation.immediate)}</td>
            {/*<td className="text-center align-middle table-active"></td>*/}
          </tr>

          {/* Forfait déplacement */}
          {hasTravelRCPPackage && (
            <tr>
              <td className="text-center align-middle">Forfait déplacement</td>
              <td className="text-center align-middle pl-5">
                {travelPackageManageable && (
                  <InputPrice
                    name="travelRCPPackage.immediateOvverride"
                    price={travelRCPPackage.immediate}
                    onChange={onChange}
                    width={80}
                  />
                )}
                {!travelPackageManageable && euro(travelRCPPackage.immediate)}
              </td>
              <td className="text-center align-middle pl-5">
                {travelPackageManageable && (
                  <InputPrice
                    name="travelRCPPackage.defferedOverride"
                    price={travelRCPPackage.deffered}
                    onChange={onChange}
                    width={80}
                  />
                )}
                {!travelPackageManageable && euro(travelRCPPackage.deffered)}
              </td>
              {/*<td className="text-center align-middle table-active"></td>*/}
              <td className="text-center align-middle">
                {euro(travelRCPPackage.immediate + travelRCPPackage.deffered)}
              </td>
              {/*<td className="text-center align-middle table-active"></td>*/}
            </tr>
          )}

          <tr>
            <td className="text-center align-middle">Total TTC</td>
            <td className="text-center align-middle pl-5">{euro(totalColumnImmediate)}</td>
            <td className="text-center align-middle pl-5">{euro(totalColumnDeffered)}</td>
            {/*<td className="text-center align-middle">{euro(compensatoryPackages.total)}</td>*/}
            <td className="text-center align-middle">{euro(totalColumnTotal)}</td>
            {/*<td className="text-center align-middle">{euro(totalColumnPresented)}</td>*/}
          </tr>

          <Franchise
            onChange={onChange}
            totalColumnImmediate={totalColumnImmediate}
            totalColumnDeffered={totalColumnDeffered}
            totalColumnTotal={totalColumnTotal}
            totalColumnPresented={false}
          />
        </tbody>
      </table>
    </React.Fragment>
  )
}

export default inject('CartStore', 'ManagerClaimStore')(observer(Settlements))
