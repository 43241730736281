import React, { useEffect } from 'react'
import { observer, inject } from 'mobx-react'
import { compose } from 'recompose'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import { path } from 'ramda'

import CardLayout from 'components/CardLayout/CardLayout'
import Form from 'components/DynForm/Form'
import Loader from 'components/Loader/Loader'

const Instruction = ({
  InstructionCtrl: { loading, form, save },
  CartStore: { instructionsChanged },
}) => {
  const { id } = useParams()
  const history = useHistory()
  const location = useLocation()
  const focus = path(['state', 'focus'], location) || null

  useEffect(() => {
    return () => form && form.resetForm()
  }, [form])

  const nextStep = () => history.push(`/managerClaim/${id}/orientation`)

  if (loading || !form) return <Loader />

  return (
    <CardLayout>
      <div className="coverage-details-page">
        <Form
          formObj={form}
          nextStep={nextStep}
          focus={focus}
          saveService={save.bind(this, id)}
          additionalSaveCondition={instructionsChanged}
          goToNextStepAfterSave={true}
          forceEnable={true}
        />
      </div>
    </CardLayout>
  )
}

export default compose(
  inject('InstructionCtrl', 'CartStore'),
  observer,
)(Instruction)
