import React, { useState } from 'react'
import { inject, observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import { path } from 'ramda'
import { compose } from 'recompose'

import ClaimMap from 'components/AppointmentMap/ClaimMap'
import { formatDate, concatAddress, concatNames, formatPhone, isInspectionMission } from 'utils'
import CardLayout from 'components/CardLayout/CardLayout'
import FieldsBloc from 'components/FieldsBloc'
import Field from 'components/Field'
import InformationWorkCFA from './InformationWorkCFA'
import MissionModal from './MissionModal'

const MissionInformation = ({
  t,
  ClaimStore: { claim: clm, loading },
  UserStore: { isExpert },
  MissionStore: { isWorkCFA, cfa, showDelayButton, isIME },
}) => {
  const [showModal, setShowModal] = useState(false)
  const claim = isExpert ? cfa.cfi : clm

  if (loading) return null

  if (isExpert && isWorkCFA) return <InformationWorkCFA />

  const outOfDarva = path(['matmutOutOfDarva'], claim)

  const thirdPartyType =
    path(['claimantInformation', 'claimant', 'qualifying'], claim) === 'ASSURE OU SOCIETAIRE'
      ? t('mission.infoMission.thirdPartyType1')
      : t('mission.infoMission.thirdPartyType2')

  const addressLoss = concatAddress(path(['claimInformation', 'addressOfLoss'], claim))
  const missionInsurerInfoAddress = concatAddress(
    path(['mission', 'missionInsurerInformation', 'address'], claim),
  )
  const addressValue =
    addressLoss.toUpperCase() !== missionInsurerInfoAddress.toUpperCase()
      ? [addressLoss, missionInsurerInfoAddress]
      : addressLoss
  return (
    <CardLayout className="claim">
      {showModal && <MissionModal {...{ showModal, setShowModal }} />}
      <Field
        name={t('mission.infoMission.type')}
        value={path(['claimInformation', 'coverage', 'value'], claim)}
      />
      <Field
        name={t('mission.infoMission.missionNumber')}
        value={path(['claimInformation', 'insurerMissionId'], claim)}
      />
      {path(['assignment', 'missionDate'], claim) && (
        <div className="row">
          <div className="col-4 col-sm-3 col-md-3 col-lg-3">
            {t('mission.infoMission.appointmentDate')}
          </div>
          <div className="col d-flex">
            <div className="flex-grow-1">
              {formatDate(path(['assignment', 'missionDate'], claim))}
            </div>
            {isExpert && showDelayButton && (
              <button className="btn btn-primary btn-sm" onClick={() => setShowModal(true)}>
                {t('mission.infoMission.modalDelay.buttonDelay')}
              </button>
            )}
          </div>
        </div>
      )}
      <Field
        name={t('mission.infoMission.expertiseDate')}
        value={formatDate(path(['assignment', 'expertiseDate'], claim))}
      />

      <Field
        name={t('mission.infoMission.missionNature')}
        value={path(['mission', 'missionInsurerInformation', 'missionNature', 'value'], claim)}
      />

      <Field
        name={t('mission.infoMission.expertiseCharacteristic')}
        value={path(
          ['mission', 'missionInsurerInformation', 'expertiseCharacteristic', 'value'],
          claim,
        )}
      />

      {isInspectionMission(claim) && (
        <Field
          name={t('messaging.inbox.isUrgentMission')}
          value={
            path(['matmutOutOfDarva', 'missionUrgente'], claim) ? t('common.yes') : t('common.no')
          }
        />
      )}

      {path(['matmutOutOfDarva'], claim) && (
        <Field
          name={t('mission.infoMission.nature')}
          value={t('mission.infoMission.inspection')}
        ></Field>
      )}

      <Field
        name={t('mission.infoMission.thirdPartyRole')}
        value={path(['claimantInformation', 'claimant', 'qualifying'], claim)}
      />
      {!outOfDarva && (
        <Field
          name={`${t('mission.infoMission.thirdPartyName')} ${thirdPartyType}`}
          value={concatNames(
            path(['claimantInformation', 'claimant', 'lastName'], claim),
            path(['claimantInformation', 'claimant', 'firstName'], claim),
          )}
        />
      )}
      {!outOfDarva && (
        <Field
          name={`${t('mission.infoMission.thirdPartyEmail')} ${thirdPartyType}`}
          value={path(['claimantInformation', 'claimant', 'email'], claim)}
          email
        />
      )}
      {!outOfDarva && (
        <Field
          name={`${t('mission.infoMission.thirdPartyPhoneNumber')} ${thirdPartyType}`}
          value={
            path(['claimantInformation', 'claimant', 'phoneNumber'], claim)
              ? formatPhone(path(['claimantInformation', 'claimant', 'phoneNumber'], claim))
              : '-'
          }
        />
      )}

      {outOfDarva && (
        <Field
          name={t('mission.infoMission.victimName')}
          value={path(['matmutOutOfDarva', 'victimName'], claim)}
        />
      )}

      {outOfDarva && (
        <Field
          name={t('mission.infoMission.contactName')}
          value={path(['matmutOutOfDarva', 'contactName'], claim)}
        />
      )}

      {outOfDarva && (
        <Field
          name={t('mission.infoMission.contactEmail')}
          value={path(['matmutOutOfDarva', 'contactEmail'], claim)}
        />
      )}

      {outOfDarva && (
        <Field
          name={t('mission.infoMission.contactPhoneNumber')}
          value={path(['matmutOutOfDarva', 'contactPhoneNumber'], claim)}
        />
      )}

      <Field
        name={t('mission.infoMission.woundNature')}
        value={path(['matmutOutOfDarva', 'natureBlessureTrans'], claim)}
      />
      <Field
        name={t('mission.infoMission.severityCode')}
        value={path(['matmutOutOfDarva', 'severityCode', 'value'], claim)}
      />
      <Field
        name={t('mission.infoMission.expertiseAddress')}
        value={addressValue}
        className={Array.isArray(addressValue) ? 'crossout-first-li' : ''}
      />
      {isInspectionMission(claim) && (
        <Field
          name={t('mission.infoMission.lieuDitMission')}
          value={path(['matmutOutOfDarva', 'lieuDitMission'], claim)}
        />
      )}
      {!!path(['claimInformation', 'addressOfLoss', 'geometry', 'lat'], claim) ? (
        <ClaimMap destination={path(['claimInformation', 'addressOfLoss', 'geometry'], claim)} />
      ) : (
        <div className="noGeometry col-8 col-sm-9 col-md-9 col-lg-9 offset-4 offset-sm-3 offset-md-3 offset-lg-3">
          {t('mission.information.map.noGeometry')}
        </div>
      )}
      <FieldsBloc
        title={t('mission.infoMission.additionalInfos')}
        fields={[
          {
            name: t('mission.infoMission.site'),
            value: path(['matmutOutOfDarva', 'siteGestion'], claim),
          },
          {
            name: t('mission.infoMission.agency'),
            value: path(['mission', 'missionInsurerInformation', 'agency'], claim),
          },
          {
            name: t('mission.infoMission.missionMotive'),
            value: path(['mission', 'missionInsurerInformation', 'reasonFor'], claim),
          },
          {
            name: t('mission.infoMission.reassignMessage'),
            value: path(['reassignmentMessage'], claim),
          },
          {
            name: t('mission.infoMission.closureMessage'),
            value: path(['cancellationClosureMessage'], claim),
          },
          {
            name: t('mission.infoMission.agentPresence'),
            value: path(['agentPresence'], claim),
          },
          {
            name: t('mission.infoMission.honorary'),
            value: path(['assignment', 'honorary'], claim),
          },
          {
            name: t('mission.infoMission.authorizedPayment'),
            value: isIME ? path(['assignment', 'authorizedPayment'], claim) : null,
          },
          {
            name: t('mission.infoMission.precision'),
            value: path(['assignment', 'specialInstructions'], claim),
          },
          {
            name: t('mission.infoMission.comment'),
            value: path(['mission', 'missionInsurerInformation', 'comment'], claim),
          },
          {
            name: t('mission.infoMission.intervention'),
            value: path(['matmutOutOfDarva', 'precisionIntervention', 'value'], claim),
          },
          {
            name: t('mission.infoMission.precisionInformationsTiersPayeurs'),
            value: path(['matmutOutOfDarva', 'precisionInformationsTiersPayeurs', 'value'], claim),
          },
          {
            name: t('mission.infoMission.prestationContractuelles'),
            value: path(['matmutOutOfDarva', 'prestationContractuelles', 'value'], claim),
          },
          {
            name: t('mission.infoMission.precisionPrestationContractuelles'),
            value: path(['matmutOutOfDarva', 'precisionsPrestation'], claim),
          },
          {
            name: t('mission.infoMission.comment'),
            value: path(['matmutOutOfDarva', 'comment'], claim),
          },
          {
            name: t('mission.infoMission.aggravatingMission'),
            value: path(['mission', 'missionInsurerInformation', 'aggravatingMission'], claim)
              ? t('common.yes')
              : t('common.no'),
          },
          {
            name: t('mission.infoMission.precisionCadreMission'),
            value: path(['matmutOutOfDarva', 'precisionCadreMission'], claim),
          },
        ]}
      />
    </CardLayout>
  )
}

export default compose(
  inject('ClaimStore', 'MissionStore', 'UserStore'),
  withTranslation(),
  observer,
)(MissionInformation)
