import React, { Fragment } from 'react'
import { observer, inject } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import ResponsiveSelect from 'components/ResponsiveSelect/ResponsiveSelect'

const InvolvedParty = ({
  inputData,
  inputData: { name, value, required, setProperty },
  PartyInvolvedStore: { formatPartyInvolved, typePartyInvolved },
  PartyInvolvedCtrl: { showForm },
  IrsiCtrl: { involvedParties },
  formInputs = null,
}) => {
  const { t } = useTranslation()
  let filtered = formatPartyInvolved

  const onChange = e => {
    // TO CHANGE EXTRA DATA OF SELECT STATUS
    if (inputData.path === 'irsi.irsiInvolvedPartys.involvedPartyId') {
      const selectStatus = formInputs.find(
        input => input.path === 'irsi.irsiInvolvedPartys.status.key',
      )
      if (selectStatus) {
        selectStatus.setProperty('extraData', { involvedPartyId: e.target.value })
      }
    }

    setProperty('personType', typePartyInvolved(e.target.value))
    setProperty('value', e.target.value)
  }

  const showModal = e => {
    e.preventDefault()
    showForm(null, false)
  }

  // remove excludeIds sends from back
  if (inputData.exclude) {
    filtered = formatPartyInvolved.filter(elem => inputData.exclude.indexOf(elem.value) === -1)
  }

  if (inputData.involvedPartyFilter) {
    filtered = formatPartyInvolved.filter(
      elem => inputData.involvedPartyFilter.indexOf(elem.role) > -1,
    )
  }

  // Filter dynform for embedded IRSI to prevent duplicate IP
  if (inputData.path === 'irsi.irsiInvolvedPartys.involvedPartyId') {
    filtered = filtered.filter(
      option => value === option.value || involvedParties.indexOf(option.value) === -1,
    )
  }

  return (
    <Fragment>
      <div className="form-group row no-gutters">
        <div className="col-8">
          <ResponsiveSelect
            required={required}
            nullable
            isSearchable
            name={name}
            value={value}
            options={filtered}
            onChange={onChange}
          />
        </div>

        <div className="col d-flex justify-content-end">
          <button className="btn btn-primary btn-sm" onClick={showModal} data-cy="create">
            {t('partyInvolved.addParty')}
          </button>
        </div>
      </div>
    </Fragment>
  )
}

export default inject('PartyInvolvedStore', 'PartyInvolvedCtrl', 'IrsiCtrl')(
  observer(InvolvedParty),
)
