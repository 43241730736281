import React, { useState } from 'react'
import { inject, observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import InputErrors from 'components/Forms/InputErrors'

import ModalWithBackdrop from 'components/ModalWithBackdrop/ModalWithBackdrop'
import Input from 'components/Forms/Input'
import Button from 'components/Button/Button'
import SearchFurnitureCtrl from 'stores/Mission/view/SearchFurnitureCtrl'
import ListSearch from 'pages/Mission/pages/Calculation/SearchFurniture/ListSearch'
import AddFurnitureCtrl from 'stores/Mission/view/AddFurnitureCtrl'
import FurnitureCatalogDetails from 'pages/Mission/pages/Calculation/FurnitureCatalogDetails'
import DatePicker from 'components/Forms/DatePicker'
import InputPrice from 'components/InputPrice/InputPrice'
import Label from 'components/Label/Label'
import ImageLoading from 'components/ImageLoading/ImageLoading'

const SearchModal = ({
  showSearchModal,
  closeSearchModal,
  selectedCategory,
  CartStore: { addFurniture },
}) => {
  const [hasCheckPrice, setHasCheckPrice] = useState(false)
  const [purchasedDateError, setPurchasedDateError] = useState([])
  const [purchasedPriceError, setPurchasedPriceError] = useState([])

  const { search } = SearchFurnitureCtrl
  const {
    furniture,
    checkPrice,
    checkingPrice,
    showModalReplacementProducts,
    replacementProducts,
  } = AddFurnitureCtrl
  const { t } = useTranslation()
  const { id } = useParams()

  const onEnterKey = e => {
    if (e.keyCode === 13 && SearchFurnitureCtrl.search.length > 0) searchFurnitures()
  }

  const searchFurnitures = () => {
    setHasCheckPrice(false)
    AddFurnitureCtrl.setProperty('furniture', null)
    SearchFurnitureCtrl.searchFurniture(id, 'regular')
  }

  const onConfirmAddFurniture = () => {
    furniture.setProperty('category', selectedCategory)
    addFurniture(furniture)
    AddFurnitureCtrl.setProperty('furniture', null)
    AddFurnitureCtrl.setProperty('showModalReplacementProducts', false)
    AddFurnitureCtrl.setProperty('replacementProducts', [])
    SearchFurnitureCtrl.setProperty('activeSearch', false)
    SearchFurnitureCtrl.setProperty('search', '')
    closeSearchModal()
  }

  const chooseProduct = furnitureReplacement => {
    AddFurnitureCtrl.setReplacementProduct(furnitureReplacement)
  }

  const onCheckPrice = () => {
    let hasError = false

    if (!furniture.dateOfPurchased) {
      setPurchasedDateError([t('mission.calculation.furniture.dateOfPurchased2IsRequired')])
      hasError = true
    }

    if (furniture.productType === 'cpi' && !furniture.purchasePrice) {
      setPurchasedPriceError([t('mission.calculation.furniture.purchasePriceIsRequired')])
      hasError = true
    }

    if (hasError) return

    furniture.setProperty('category', selectedCategory)
    setPurchasedDateError([])
    setPurchasedPriceError([])
    checkPrice()
    setHasCheckPrice(true)
  }

  const onSetPrice = price => {
    furniture.setProperty('priceWithVat', price)
    setHasCheckPrice(true)
  }

  return (
    <ModalWithBackdrop
      draggable
      scrollable
      size="modal-xl"
      show={showSearchModal}
      onClose={closeSearchModal}
    >
      <div className="modal-header d-flex justify-content-between">
        <h5 className="modal-title">
          {!showModalReplacementProducts &&
            `${t('mission.calculation.searchFurniture.search')} ${t(
              'mission.calculation.furniture.regular',
            )}`}
          {showModalReplacementProducts &&
            t('mission.calculation.furniture.replacementProductsModal.title')}
        </h5>

        <i className="fa fa-close clickable" onClick={closeSearchModal} />
      </div>
      <div className="modal-body container add-furniture">
        <div className="row no-gutters my-3">
          <div className="col-12">
            <div className="input-group" onKeyDown={onEnterKey}>
              <Input
                className="form-control"
                name="search"
                value={search}
                placeholder={`${t('mission.calculation.searchFurniture.search')} ${t(
                  'mission.calculation.furniture.regular',
                )}`}
                onChange={e => SearchFurnitureCtrl.setProperty('search', e.target.value)}
                autoComplete="off"
              />
              <div className="input-group-append">
                <Button
                  className="btn btn-primary"
                  disabled={search.length === 0}
                  onClick={searchFurnitures}
                >
                  <i className="fa fa-search" />
                </Button>
              </div>
            </div>
          </div>
        </div>

        {!furniture && (
          <ListSearch furnitureLocation="regular" className="search-result container pl-2 pr-2" />
        )}

        {furniture && !showModalReplacementProducts && (
          <div className="row no-gutters">
            <div className="col-7 pr-4">
              <FurnitureCatalogDetails furniture={furniture} className="mb-3" />
            </div>

            <div className="col-5 pl-4 mh-300">
              <div className="row m-3">
                <div className="col-5">
                  <Label required>{t('mission.calculation.furniture.dateOfPurchased2')}</Label>
                </div>
                <div className="col-7">
                  <DatePicker
                    name="dateOfPurchased"
                    className="form-control"
                    onChange={date => furniture.setProperty('dateOfPurchased', date)}
                    selected={furniture.dateOfPurchased}
                    maxDate={new Date()}
                    required={true}
                    placeholder="dd/mm/yyyy"
                    errors={purchasedDateError}
                  />
                </div>
              </div>

              {furniture.productType === 'cpi' && (
                <div className="row m-3">
                  <div className="col-5">
                    <Label required>{t('mission.calculation.furniture.purchasePrice')}</Label>
                  </div>
                  <div className="col-7">
                    <InputPrice
                      name="purchasePrice"
                      price={furniture.purchasePrice || 0}
                      classNamesForPrice="font-weight-bold text-primary"
                      onChange={e => furniture.setProperty('purchasePrice', e.target.value)}
                    />
                    {purchasedPriceError && <InputErrors errors={purchasedPriceError} />}
                  </div>
                </div>
              )}

              <div className="row m-3">
                <div className="col-5">
                  <Label required>{t('mission.calculation.furniture.totalPrice')}</Label>
                </div>
                <div className="col-7">
                  <InputPrice
                    name="priceWithVat"
                    price={hasCheckPrice && !checkingPrice ? furniture.totalPriceVAT : ''}
                    classNamesForPrice="font-weight-bold text-primary"
                    onChange={e => onSetPrice(e.target.value)}
                    disabled={furniture.canCheckPrice}
                  />
                </div>
              </div>

              <div className="row m-3">
                <div className="col-12 pull-right">
                  {!checkingPrice && (
                    <Button
                      className="btn btn-primary"
                      onClick={onCheckPrice}
                      disabled={hasCheckPrice || checkingPrice}
                    >
                      {t('mission.calculation.furniture.checkPriceBtn')}
                    </Button>
                  )}
                  {checkingPrice && <ImageLoading />}
                </div>
              </div>
            </div>
          </div>
        )}

        {furniture && showModalReplacementProducts && (
          <div className="replacementProductsModal search-result container pl-2 pr-2">
            <div className="originalProduct mb-3">
              <p>{t('mission.calculation.furniture.replacementProductsModal.originalProduct')}</p>
              <FurnitureCatalogDetails furniture={furniture} />
            </div>

            <div className="replacementProducts">
              <p>
                {t('mission.calculation.furniture.replacementProductsModal.replacementProducts')}
              </p>
              {replacementProducts.map(replacementProduct => {
                return (
                  <FurnitureCatalogDetails
                    key={replacementProduct.id}
                    furniture={replacementProduct}
                    onClick={() => chooseProduct(replacementProduct)}
                  />
                )
              })}
            </div>
          </div>
        )}
      </div>
      {furniture && !showModalReplacementProducts && (
        <div className="modal-footer">
          <Button
            className="btn btn-primary"
            onClick={onConfirmAddFurniture}
            disabled={!hasCheckPrice || checkingPrice}
          >
            {t('mission.calculation.furniture.addFurnitureBtn')}{' '}
            {t(`mission.calculation.furniture.regular`)}
          </Button>
        </div>
      )}
    </ModalWithBackdrop>
  )
}

export default inject('CartStore')(observer(SearchModal))
