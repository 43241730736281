import { observable, computed, action, decorate, runInAction } from 'mobx'
import { path } from 'ramda'

import { createManagerClaim, getManagerClaimForm } from 'services/claim'
import Form from 'utils/dynform/Form'

class CreateManagerClaimCtrl {
  showModal = false
  loading = false
  form = null

  setProperty(key, value) {
    this[key] = value
  }

  save = async formData => {
    let claim = await createManagerClaim(formData)
    return claim.wan
  }

  loadData = async () => {
    this.loading = true

    try {
      const form = await getManagerClaimForm()
      form.name = 'ClaimFileManager'
      runInAction(() => {
        this.form = new Form({ form, isCreationForm: true })
      })
    } catch (err) {
      console.error(err)
    } finally {
      runInAction(() => {
        this.loading = false
      })
    }
  }

  get isValid() {
    if (!this.form) return false

    let isValid = true

    this.form.inputs.forEach(input => {
      if (!input.required || !input.show) {
        return
      }

      if (input.value === undefined || input.value === null || input.value === '') {
        isValid = false
        return
      }

      if (input.type === 'address' && !path(['zipCode'], input.value)) {
        isValid = false
        return
      }
    })

    return isValid
  }
}

const DecoratedCreateManagerClaimCtrl = decorate(CreateManagerClaimCtrl, {
  showModal: observable,
  loading: observable,
  form: observable,

  isValid: computed,
  loadData: action,
  setProperty: action.bound,
  save: action,
})

export default new DecoratedCreateManagerClaimCtrl()
