import React, { useEffect } from 'react'
import { observer, inject } from 'mobx-react'
import { compose } from 'recompose'
import { useParams } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import Button from 'components/Button/Button'
import CardLayout from 'components/CardLayout/CardLayout'
import Loader from 'components/Loader/Loader'
import OrientationModal from 'pages/ManagerClaim/pages/Orientation/OrientationModal'
import Form from 'components/DynForm/Form'

const Orientation = ({
  OrientationCtrl: {
    loading,
    loadData,
    setProperty,
    gagAvailable,
    renAvailable,
    pecuniaryAvailable,
    payInsuredAvailable,
    teleExpertiseAvailable,
    expertiseAvailable,
    mixGagPecuniaryAvailable,
    mixRenGagAvailable,
    mixRenIpAvailable,
    insuredWillBeContactedLater,
    loadOrientationForm,
    form,
  },
  ManagerClaimStore: { isClaimClosed, orientationChoice, claim },
  t,
}) => {
  const { id } = useParams()
  useEffect(() => {
    if (isClaimClosed) {
      setProperty('choice', orientationChoice)
      loadOrientationForm(id, claim)
    } else {
      loadData(id)
    }
  }, [loadData, id, isClaimClosed, orientationChoice, loadOrientationForm, setProperty, claim])

  const changeChoice = choice => {
    setProperty('choice', choice)
    setProperty('showModal', true)
  }

  if (loading || (isClaimClosed && !form)) return <Loader />

  if (isClaimClosed && form) return <Form formObj={form} noSubmitBtn={true} />

  return (
    <CardLayout>
      <OrientationModal />
      <div className="d-flex mb-4">
        {gagAvailable && (
          <Button className="btn btn-primary mr-4 mb-4" onClick={() => changeChoice('gag')}>
            {t('managerClaim.orientation.gag')}
          </Button>
        )}

        {renAvailable && (
          <Button className="btn btn-primary mr-4 mb-4" onClick={() => changeChoice('ren')}>
            {t('managerClaim.orientation.ren')}
          </Button>
        )}

        {pecuniaryAvailable && (
          <Button className="btn btn-primary mr-4 mb-4" onClick={() => changeChoice('pecuniary')}>
            {t('managerClaim.orientation.pecuniary')}
          </Button>
        )}

        {teleExpertiseAvailable && (
          <Button className="btn btn-primary mr-4 mb-4" onClick={() => changeChoice('teleExpert')}>
            {t('managerClaim.orientation.teleExpert')}
          </Button>
        )}

        {payInsuredAvailable && (
          <Button className="btn btn-primary mr-4 mb-4" onClick={() => changeChoice('pay_insured')}>
            {t('managerClaim.orientation.payInsured')}
          </Button>
        )}

        {mixGagPecuniaryAvailable && (
          <Button
            className="btn btn-primary mr-4 mb-4"
            onClick={() => changeChoice('mix_gag_pecuniary')}
          >
            {t('managerClaim.orientation.mix_gag_pecuniary')}
          </Button>
        )}

        {mixRenGagAvailable && (
          <Button className="btn btn-primary mr-4 mb-4" onClick={() => changeChoice('mix_ren_gag')}>
            {t('managerClaim.orientation.mix_ren_gag')}
          </Button>
        )}

        {mixRenIpAvailable && (
          <Button className="btn btn-primary mr-4 mb-4" onClick={() => changeChoice('mix_ren_ip')}>
            {t('managerClaim.orientation.mix_ren_ip')}
          </Button>
        )}

        {expertiseAvailable && (
          <Button className="btn btn-primary mr-4 mb-4" onClick={() => changeChoice('expert')}>
            {t('managerClaim.orientation.expert')}
          </Button>
        )}
      </div>

      {insuredWillBeContactedLater && (
        <div className="d-flex flex-row">{t('managerClaim.orientation.contactLater')}</div>
      )}
    </CardLayout>
  )
}

export default compose(
  inject('ManagerClaimStore', 'OrientationCtrl'),
  withTranslation(),
  observer,
)(Orientation)
