import { observable, computed, action, decorate, runInAction } from 'mobx'
import { path } from 'ramda'
import { format } from 'date-fns'
import i18next from 'i18next'

import AlertCtrl from 'stores/Common/view/AlertCtrl'
import UserStore from 'stores/Common/domain/UserStore'
import InboxStore from 'stores/Messaging/InboxStore'
import MessagingStore from 'stores/Messaging/MessagingStore'
import SupportingDocumentStore from 'stores/Common/domain/SupportingDocumentStore'
import SupportingDocumentsCtrl from 'stores/Common/view/SupportingDocumentsCtrl'
import { postMarkAsRead, duplicateAttachments } from 'services/messaging'

class Message {
  id = null
  wanSource = null
  wanDest = null
  text = ''
  senderUser = null
  receiverUser = null
  customerIdDest = null
  readAt = null
  documents = []
  selectingDocuments = []
  createdAt = null
  markingAsRead = false
  type = null
  savingAttachments = false
  wanType = null
  isUrgent = false
  context = ''

  constructor(data) {
    if (data) {
      Object.assign(this, data)

      const senderUserId = path(['senderUser', 'id'], data)
      if (senderUserId) {
        this.senderUser = {
          id: senderUserId,
          firstName: path(['senderUser', 'firstName'], data) || '',
          lastName: path(['senderUser', 'lastName'], data) || '',
        }
      } else this.senderUser = null

      const receiverUserId = path(['receiverUser', 'id'], data)
      if (receiverUserId) {
        this.receiverUser = {
          id: receiverUserId,
          firstName: path(['receiverUser', 'firstName'], data) || '',
          lastName: path(['receiverUser', 'lastName'], data) || '',
        }
      } else this.receiverUser = null
    }
  }

  get asJson() {
    return {
      text: this.text,
      isUrgent: this.isUrgent,
      documents: this.documents,
    }
  }

  get isSelf() {
    let isSelf = path(['id'], this.senderUser) === UserStore.id

    if (
      path(['id'], this.senderUser) === UserStore.id &&
      UserStore.hasDoubleRoleExpertAndInsurer &&
      UserStore.isExpert &&
      this.context === 'sendToExpert'
    ) {
      isSelf = false
    }
    if (
      path(['id'], this.senderUser) === UserStore.id &&
      UserStore.hasDoubleRoleExpertAndInsurer &&
      UserStore.isManager &&
      this.context === 'sendToPole'
    ) {
      isSelf = false
    }
    return isSelf
  }

  get canMarkAsRead() {
    if (
      this.senderUser === null &&
      this.receiverUser !== null &&
      this.receiverUser.id === UserStore.id
    ) {
      return true
    } else if (this.senderUser !== null && this.receiverUser === null) {
      return true
    } else if (this.senderUser && this.receiverUser && this.receiverUser.id === UserStore.id) {
      return true
    } else if (this.senderUser === null && this.type === 'DARVA') {
      return true
    }
    return false
  }

  get senderFullName() {
    if (!this.senderUser) return null
    return `${this.senderUser.firstName} ${this.senderUser.lastName}`
  }

  get receiverFullName() {
    if (!this.receiverUser) return null
    return `${this.receiverUser.firstName} ${this.receiverUser.lastName}`
  }

  get from() {
    if (!this.senderFullName)
      return `${i18next.t('messaging.message.sender', {
        sender: i18next.t('messaging.message.darva'),
      })}`
    return `${i18next.t('messaging.message.sender', { sender: this.senderFullName })}`
  }

  get at() {
    if (this.type === 'DARVA' && this.senderUser !== null) {
      return `${i18next.t('messaging.message.receiver', {
        receiver: i18next.t('messaging.message.darva'),
      })}`
    } else if (this.receiverFullName === null && this.type === 'DARVA') {
      return `${i18next.t('messaging.message.at')} ${i18next.t('messaging.pole')}`
    } else if (this.receiverFullName === null) {
      if (this.context === 'sendToManager') {
        return `${i18next.t('messaging.message.at')} ${i18next.t('messaging.manager')}`
      }
      if (this.context === 'sendToPole') {
        return `${i18next.t('messaging.message.at')} ${i18next.t('messaging.pole')}`
      } else {
        return `${i18next.t('messaging.message.at')} ${i18next.t('messaging.secretary')}`
      }
    } else if (this.receiverFullName === null) {
      return null
    }
    return `${i18next.t('messaging.message.receiver', { receiver: this.receiverFullName })}`
  }

  get formatCreatedDate() {
    const date = new Date(this.createdAt)
    return `${format(date, 'dd/MM/y')} ${i18next.t('common.at')} ${format(date, 'k:mm')}`
  }

  get formatReadAt() {
    if (this.readAt) {
      const date = new Date(this.readAt)
      return `${format(date, 'dd/MM/y')} ${i18next.t('common.at')} ${format(date, 'k:mm')}`
    }
    return null
  }

  get attachments() {
    return SupportingDocumentStore.supportingDocuments.filter(
      sd => this.documents.indexOf(sd.id) > -1,
    )
  }

  setMessage(data) {
    Object.assign(this, data)
  }

  async markAsRead() {
    this.markingAsRead = true
    try {
      await postMarkAsRead(this.id)
      runInAction(() => {
        InboxStore.markAsReadNotification()
        MessagingStore.markAsReadNotification()
        this.markingAsRead = false
        this.readAt = new Date()
      })
    } catch (error) {
      console.error(error)
      AlertCtrl.alert('danger', 'messaging.message.markAsReadError')
    }
  }

  async saveAttachments(wan) {
    const url = UserStore.isExpert
      ? `/claimFiles/v1/claimFileAssessor/${wan}/duplicate`
      : `/claimFiles/v1/${wan}/duplicate`
    this.savingAttachments = true
    try {
      await duplicateAttachments({ url, attachmentIds: this.documents })
      SupportingDocumentsCtrl.fetchSupportingDocuments(wan)
      runInAction(() => {
        this.savingAttachments = false
      })
      AlertCtrl.alert(
        'success',
        'messaging.message.savingAttachmentsSuccess',
        false,
        this.documents.length,
      )
    } catch (error) {
      runInAction(() => {
        this.savingAttachments = false
      })
      AlertCtrl.alert(
        'danger',
        'messaging.message.savingAttachmentsError',
        false,
        this.documents.length,
      )
    }
  }

  selectDocument(documentId) {
    if (this.selectingDocuments.indexOf(documentId) === -1) this.selectingDocuments.push(documentId)
    else
      this.selectingDocuments = this.selectingDocuments.filter(document => document !== documentId)
  }

  deleteDocument(documentId) {
    this.documents = this.documents.filter(document => document !== documentId)
  }

  setProperty(key, value) {
    this[key] = value
  }
}

export default decorate(Message, {
  text: observable,
  readAt: observable,
  documents: observable,
  selectingDocuments: observable,
  markingAsRead: observable,
  savingAttachments: observable,
  isUrgent: observable,

  isSelf: computed,
  senderFullName: computed,
  asJson: computed,
  receiverFullName: computed,
  attachments: computed,
  from: computed,
  at: computed,
  canMarkAsRead: computed,

  setProperty: action.bound,
  setMessage: action.bound,
  markAsRead: action.bound,
  selectDocument: action.bound,
  deleteDocument: action.bound,
  saveAttachments: action.bound,
})
