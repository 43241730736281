import { observable, computed, action, decorate } from 'mobx'

import AbstractField from 'utils/dynform/AbstractField'

class Address extends AbstractField {
  value = ''
  errors = []
  show = true
  originalValue = null

  type = 'address'

  multiple = false
  expanded = false
  children = null

  constructor(data) {
    super(data)

    this.show = data.show
    this.type = data.type || 'address'

    if (data.value && this.isValidAddress(data.value)) this.value = data.value
    else if (data.default) this.value = data.default
    else this.value = ''

    this.originalValue = this.value
  }

  isValidAddress(addr) {
    return addr.zipCode !== null
  }

  get changed() {
    return this.value !== this.originalValue
  }

  setOriginalData() {
    this.value = this.originalValue
  }
}

const DecoratedAddress = decorate(Address, {
  value: observable,
  originalValue: observable,
  errors: observable,
  show: observable,

  setOriginalData: action,

  changed: computed,
})
export default DecoratedAddress
