import React from 'react'
import { observer } from 'mobx-react'

import Input from 'components/Forms/Input'

const InputStringWrapper = ({ inputData }) => {
  const onChange = e => inputData.setProperty('value', e.target.value)

  if (inputData.hasRandomButton)
    return (
      <div className="input-group">
        <div className="input-group-prepend">
          <i
            className="btn btn-primary fa fa-random"
            onClick={() => inputData.setProperty('value', new Date().getTime().toString())}
          />
        </div>
        <Input className="form-control" {...inputData} onChange={onChange} />
      </div>
    )

  return <Input className="form-control" {...inputData} onChange={onChange} />
}
export default observer(InputStringWrapper)
