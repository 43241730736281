import { add, subtract, divide, isNil } from 'ramda'

import { roundDecimal } from 'utils/currency'
import { calculTotalPackages, computeCatalogOutdoorPackages } from 'utils/cart'

const rules = {
  furniture: ['defferedSettlement'],
  overflow: ['immediateSettlement', 'defferedSettlement', 'totalContractSettlement'],
  noGuarantee: ['immediateSettlement', 'defferedSettlement', 'totalContractSettlement'],
}

export const list = [
  'property',
  'embellishment',
  'furniture',
  'leak',
  'relatedCost',
  'outdoor',
  'options',
  'overflow',
  'noGuarantee',
]

export const column = [
  'immediateSettlement',
  'defferedSettlement',
  'totalContractSettlement',
  'settlementPresented',
  'totalPresented',
]

export const specialRules = ['property', 'embellishment', 'outdoor']
export const skip = ['overflow', 'noGuarantee']

export const computeImmediateSpecialRuling = ({
  configuration,
  finalPrice,
  withOBWithoutVAT,
  totalContractSettlement,
}) => {
  const {
    instructionDividend,
    instructionFloor,
    instructionLowCeilling,
    instructionHighCeilling,
  } = configuration

  if (
    totalContractSettlement <= instructionFloor ||
    (instructionLowCeilling < totalContractSettlement &&
      totalContractSettlement <= instructionHighCeilling)
  ) {
    return finalPrice
  } else if (
    instructionFloor < totalContractSettlement &&
    totalContractSettlement <= instructionLowCeilling
  ) {
    return roundDecimal(divide(finalPrice, instructionDividend))
  } else {
    return withOBWithoutVAT
  }
}

export const computeDefferedSpecialRuling = ({
  configuration,
  finalPrice,
  withOBWithoutVAT,
  totalContractSettlement,
}) => {
  const {
    instructionDividend,
    instructionFloor,
    instructionLowCeilling,
    instructionHighCeilling,
  } = configuration

  if (
    totalContractSettlement <= instructionFloor ||
    (instructionLowCeilling < totalContractSettlement &&
      totalContractSettlement <= instructionHighCeilling)
  ) {
    return 0
  } else if (
    instructionFloor < totalContractSettlement &&
    totalContractSettlement <= instructionLowCeilling
  ) {
    return subtract(finalPrice, roundDecimal(divide(finalPrice, instructionDividend)))
  } else {
    return subtract(finalPrice, withOBWithoutVAT)
  }
}

export const computeInstructionsRules = (configuration, total, withOBWithoutVAT) => {
  const {
    instructionDividend,
    instructionFloor,
    instructionLowCeilling,
    instructionHighCeilling,
  } = configuration

  if (
    total <= instructionFloor ||
    (instructionLowCeilling < total && total <= instructionHighCeilling)
  ) {
    return { immediate: total, deffered: 0 }
  } else if (instructionFloor < total && total <= instructionLowCeilling) {
    const immediate = roundDecimal(divide(total, instructionDividend))
    return { immediate, deffered: total - immediate }
  } else {
    return { immediate: withOBWithoutVAT, deffered: total - withOBWithoutVAT }
  }
}

export const computeTotalContractSettlement = ({
  finalPrice,
  immediate,
  deffered,
  immediateOverride,
  defferedOverride,
}) => {
  if (immediateOverride !== null && defferedOverride !== null)
    return immediateOverride + defferedOverride
  else if (immediateOverride !== null) return immediateOverride + deffered
  else if (defferedOverride !== null) return immediate + defferedOverride

  return finalPrice
}

export const showInput = (name, column) => {
  if (!rules[name]) return true
  return rules[name].indexOf(column) === -1
}

export const computeIRSI2SettlementCatalogSelfRepair = ({
  packages,
  affectedBy,
  catalogKey,
  selfRepairKey,
}) => {
  const affectedByPackage = packages.filter(affectedBy)
  const total1 = calculTotalPackages({
    packages: affectedByPackage,
    key: catalogKey,
    selfRepair: false,
  })
  const total2 = calculTotalPackages({
    packages: affectedByPackage,
    key: selfRepairKey,
    selfRepair: true,
  })

  return roundDecimal(add(total1, total2))
}

export const computeIRSI2Settlement = ({ packages, affectedBy, valueKey }) => {
  return roundDecimal(
    packages.filter(affectedBy).reduce((acc, data) => add(acc, data[valueKey]), 0),
  )
}

export const computeIRSI2SettlementOutdoor = ({
  outdoorPackages,
  affectedBy,
  furnitureKey,
  catalogKey,
  selfRepairKey,
}) => {
  const affectedByOutdoorPackage = outdoorPackages.filter(affectedBy)
  const total1 = computeCatalogOutdoorPackages({
    outdoorPackages: affectedByOutdoorPackage,
    furnitureKey,
    packageKey: catalogKey,
  })
  const total2 = calculTotalPackages({
    packages: affectedByOutdoorPackage,
    key: selfRepairKey,
    selfRepair: true,
  })

  return roundDecimal(add(total1, total2))
}

export const computeIRSI2WithOBWithoutVAT = ({
  packages,
  affectedBy,
  catalogKey,
  selfRepairKey,
  returnValue,
  furnitureKey = null,
}) => {
  const affectedByPackage = packages.filter(affectedBy)

  let total1 = 0
  if (isNil(furnitureKey)) {
    total1 = calculTotalPackages({
      packages: affectedByPackage,
      key: catalogKey,
      selfRepair: false,
    })
  } else {
    total1 = computeCatalogOutdoorPackages({
      outdoorPackages: affectedByPackage,
      furnitureKey: furnitureKey,
      packageKey: catalogKey,
    })
  }

  const total2 = calculTotalPackages({
    packages: affectedByPackage,
    key: selfRepairKey,
    selfRepair: true,
  })

  return roundDecimal(add(total1, subtract(total2, returnValue)))
}

export const initFinalPrice = (
  catalog,
  selfRepair,
  totalRelatedCostsAnnexPriceWithVAT,
  totalRelatedCostsAnnexPriceWithoutVAT,
  prejudiceFinalPrice,
  prejudiceFinalPriceWithoutVAT,
  computeWithoutVAT,
) => ({
  property: add(
    catalog[computeWithoutVAT ? 'totalPropertyFinalPriceWithoutVAT' : 'totalPropertyFinalPrice'],
    selfRepair[computeWithoutVAT ? 'totalPropertyFinalPriceWithoutVAT' : 'totalPropertyFinalPrice'],
  ),
  embellishment: add(
    catalog[
      computeWithoutVAT ? 'totalEmbellishmentFinalPriceWithoutVAT' : 'totalEmbellishmentFinalPrice'
    ],
    selfRepair[
      computeWithoutVAT ? 'totalEmbellishmentFinalPriceWithoutVAT' : 'totalEmbellishmentFinalPrice'
    ],
  ),
  furniture:
    catalog[computeWithoutVAT ? 'totalFurnitureFinalPriceWithoutVAT' : 'totalFurnitureFinalPrice'],
  leak: catalog[computeWithoutVAT ? 'totalLeakFinalPriceWithoutVAT' : 'totalLeakFinalPrice'],
  relatedCost: computeWithoutVAT
    ? totalRelatedCostsAnnexPriceWithoutVAT
    : totalRelatedCostsAnnexPriceWithVAT,
  outdoor: add(
    catalog[computeWithoutVAT ? 'totalOutdoorFinalPriceWithoutVAT' : 'totalOutdoorFinalPrice'],
    selfRepair[computeWithoutVAT ? 'totalOutdoorFinalPriceWithoutVAT' : 'totalOutdoorFinalPrice'],
  ),
  options: computeWithoutVAT ? prejudiceFinalPriceWithoutVAT : prejudiceFinalPrice,
})

export const initReturns = (catalog, selfRepair) => ({
  property: add(catalog.totalPropertyORWithoutVAT, selfRepair.totalPropertyORWithoutVAT),
  embellishment: add(
    catalog.totalEmbellishmentORWithoutVAT,
    selfRepair.totalEmbellishmentORWithoutVAT,
  ),
  outdoor: add(catalog.totalOutdoorORWithoutVAT, selfRepair.totalOutdoorORWithoutVAT),
})

export const initWithOBWithoutVAT = (catalog, selfRepair, returns) => ({
  property: add(
    catalog.totalPropertyWithoutObsolescenceWithoutVAT,
    subtract(selfRepair.totalPropertyWithoutObsolescenceWithoutVAT, returns.property),
  ),
  embellishment: add(
    catalog.totalEmbellishmentWithoutObsolescenceWithoutVAT,
    subtract(selfRepair.totalEmbellishmentWithoutObsolescenceWithoutVAT, returns.embellishment),
  ),
  outdoor: add(
    catalog.totalOutdoorWithoutObsolescenceWithoutVAT,
    subtract(selfRepair.totalOutdoorWithoutObsolescenceWithoutVAT, returns.outdoor),
  ),
})

export const resetComputations = (finalPrice, returns, withOBWithoutVAT) => {
  finalPrice.property = 0
  finalPrice.embellishment = 0
  finalPrice.furniture = 0
  finalPrice.leak = 0
  finalPrice.relatedCost = 0
  finalPrice.outdoor = 0
  finalPrice.options = 0

  returns.property = 0
  returns.embellishment = 0
  returns.outdoor = 0

  withOBWithoutVAT.property = 0
  withOBWithoutVAT.embellishment = 0
  withOBWithoutVAT.outdoor = 0
}

export const irsiComputations = (
  finalPrice,
  returns,
  withOBWithoutVAT,
  affectedBy,
  propertyPackages,
  embellishmentPackages,
  regularFurnitures,
  leakPackages,
  relatedCostsAnnex,
  outdoorPackages,
  computeWithoutVAT,
) => {
  finalPrice.property = computeIRSI2SettlementCatalogSelfRepair({
    packages: propertyPackages,
    affectedBy,
    catalogKey: computeWithoutVAT ? 'finalPriceCatalogWithoutVAT' : 'finalPriceCatalog',
    selfRepairKey: computeWithoutVAT ? 'finalPriceSelfRepairWithoutVAT' : 'finalPriceSelfRepair',
  })
  finalPrice.embellishment = computeIRSI2SettlementCatalogSelfRepair({
    packages: embellishmentPackages,
    affectedBy,
    catalogKey: computeWithoutVAT ? 'finalPriceCatalogWithoutVAT' : 'finalPriceCatalog',
    selfRepairKey: computeWithoutVAT ? 'finalPriceSelfRepairWithoutVAT' : 'finalPriceSelfRepair',
  })
  finalPrice.furniture = computeIRSI2Settlement({
    packages: regularFurnitures,
    affectedBy,
    valueKey: computeWithoutVAT ? 'finalPriceWithoutVAT' : 'finalPrice',
  })
  finalPrice.leak = computeIRSI2Settlement({
    packages: leakPackages,
    affectedBy,
    valueKey: computeWithoutVAT ? 'finalPriceCatalogWithoutVAT' : 'finalPriceCatalog',
  })
  finalPrice.relatedCost = computeIRSI2Settlement({
    packages: relatedCostsAnnex,
    affectedBy,
    valueKey: computeWithoutVAT ? 'priceWithoutVAT' : 'priceWithVAT',
  })
  finalPrice.outdoor = computeIRSI2SettlementOutdoor({
    outdoorPackages: outdoorPackages,
    affectedBy,
    furnitureKey: computeWithoutVAT ? 'finalPriceWithoutVAT' : 'finalPrice',
    catalogKey: computeWithoutVAT ? 'finalPriceCatalogWithoutVAT' : 'finalPriceCatalog',
    selfRepairKey: computeWithoutVAT ? 'finalPriceSelfRepairWithoutVAT' : 'finalPriceSelfRepair',
  })

  returns.property = computeIRSI2Settlement({
    packages: propertyPackages,
    affectedBy,
    catalogKey: 'totalCatalogORWithoutVAT',
    selfRepairKey: 'totalSelfRepairORWithoutVAT',
  })
  returns.embellishment = computeIRSI2Settlement({
    packages: embellishmentPackages,
    affectedBy,
    catalogKey: 'totalCatalogORWithoutVAT',
    selfRepairKey: 'totalSelfRepairORWithoutVAT',
  })
  returns.outdoor = computeIRSI2SettlementOutdoor({
    outdoorPackages: outdoorPackages,
    affectedBy,
    furnitureKey: 'ORWithoutVAT',
    catalogKey: 'totalCatalogORWithoutVAT',
    selfRepairKey: 'totalSelfRepairORWithoutVAT',
  })

  withOBWithoutVAT.property = computeIRSI2WithOBWithoutVAT({
    packages: propertyPackages,
    affectedBy,
    catalogKey: 'quantityOriginalTotalPriceCatalog',
    selfRepairKey: 'quantityOriginalTotalPriceSelfRepair',
    returnValue: returns.property,
  })
  withOBWithoutVAT.embellishment = computeIRSI2WithOBWithoutVAT({
    packages: embellishmentPackages,
    affectedBy,
    catalogKey: 'quantityOriginalTotalPriceCatalog',
    selfRepairKey: 'quantityOriginalTotalPriceSelfRepair',
    returnValue: returns.embellishment,
  })
  withOBWithoutVAT.outdoor = computeIRSI2WithOBWithoutVAT({
    packages: propertyPackages,
    affectedBy,
    catalogKey: 'quantityOriginalTotalPriceCatalog',
    furnitureKey: 'price',
    selfRepairKey: 'quantityOriginalTotalPriceSelfRepair',
    returnValue: returns.outdoor,
  })
}
