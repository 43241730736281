import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { splitEvery } from 'ramda'

import PropertyEmbellishmentCtrl from 'stores/Mission/EasyEstimation/PropertyEmbellishmentCtrl'
import QualityMaterials from 'pages/Mission/pages/Calculation/DetailPackage/QualityMaterials'
import Button from 'components/Button/Button'
import CoveringImg from './CoveringImg'
import CoveringUnit from './CoveringUnit'
import PercentageDammaged from './PercentageDammaged'
import { euro } from 'utils/currency'

const CoveringForm = ({ mode, show, closeForm, store }) => {
  const [showItems, setShowItems] = useState(false)

  const { t } = useTranslation()
  const {
    setProperty,
    currentParts,
    selectedPart,
    selectedCovering,
    selectedCoveringCde,
    fetchRoomCovering,
    fetching,
    items,
    qualityType,
    changePackagesProperty,
    packages,
    isSquareMeter,
    totalCoveringPerUnity,
    addCovering,
    resetCoveringForm,
  } = PropertyEmbellishmentCtrl

  useEffect(() => {
    if (items.length > 0) {
      setShowItems(true)
    }
  }, [items])

  useEffect(() => {
    if (currentParts.length === 1) {
      setProperty('selectedPart', currentParts[0].id)
    }
  }, [currentParts, setProperty])

  const coveringRows = splitEvery(4, selectedCovering)

  return (
    <div className="mb-3">
      {show && (
        <div className="border border-primary rounded p-3 mb-3">
          <div className="d-flex justify-content-between mb-3">
            <div>
              <label className="d-block">Surface endommagée</label>

              <div className="btn btn-group pl-0">
                {currentParts.map(part => (
                  <button
                    key={`button-part-${part.id}`}
                    className={classNames('btn', {
                      'btn-primary': selectedPart === part.id,
                      'btn-outline-primary': selectedPart !== part.id,
                    })}
                    onClick={() => {
                      setProperty('selectedPart', part.id)
                      setProperty('packages', [])
                    }}
                  >
                    {part.label}
                  </button>
                ))}
              </div>
            </div>

            <CoveringUnit />
          </div>

          <div className="d-flex justify-content-end mb-3">
            <PercentageDammaged />
          </div>

          {selectedCovering.length > 0 && (
            <div className="mb-3">
              <label className="d-block">Revêtement / Prestations</label>
              {coveringRows.map((row, i) => (
                <div className="d-flex flex-row" key={`row-covering-${i}`}>
                  {row.map(covering => (
                    <div
                      key={`covering-${covering.covering.cde}`}
                      className={classNames(
                        'covering-box d-flex flex-column align-items-center border rounded mr-2 mb-2 text-center overflow-auto pt-3',
                        {
                          clickable: !fetching,
                          'not-allowed': fetching || covering.covering.cde === selectedCoveringCde,
                          'border-primary': covering.covering.cde === selectedCoveringCde,
                          'text-primary': covering.covering.cde === selectedCoveringCde,
                          'text-muted': fetching && covering.covering.cde !== selectedCoveringCde,
                        },
                      )}
                      onClick={() => {
                        if (!fetching && covering.covering.cde !== selectedCoveringCde) {
                          fetchRoomCovering(store.id, covering.covering.cde)
                        }
                      }}
                    >
                      <CoveringImg
                        selected={covering.covering.cde === selectedCoveringCde}
                        coveringId={covering.covering.cde}
                      />
                      <span>{covering.covering.label}</span>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          )}

          {items.length > 0 && (
            <div className="mb-3">
              <label className="d-block">
                Matériaux{' '}
                <i
                  className={classNames('fa clickable', {
                    'fa-chevron-up': showItems,
                    'fa-chevron-down': !showItems,
                  })}
                  onClick={() => setShowItems(!showItems)}
                />
              </label>

              {showItems && (
                <React.Fragment>
                  <div className="d-flex justify-content-between mb-3">
                    <div>
                      <label>{t('mission.calculation.detailPackage.qualityMaterials')}</label>

                      <QualityMaterials
                        showLabel={false}
                        qualityType={qualityType}
                        materialPresent={true}
                        changeQuality={e => {
                          setProperty('qualityType', e.target.value)
                          changePackagesProperty('qualityType', e.target.value)
                        }}
                      />
                    </div>
                    {totalCoveringPerUnity > 0 && (
                      <div className="d-flex justify-content-end mb-3">
                        <div className="text-right">
                          <label className="d-block font-weight-bold">Prix unitaire HT</label>
                          {euro(totalCoveringPerUnity)}{' '}
                          {isSquareMeter ? (
                            <span>
                              / m<sup>2</sup>
                            </span>
                          ) : (
                            '/ unité'
                          )}
                        </div>
                      </div>
                    )}
                  </div>

                  {items.map(item => (
                    <div key={`item-${item.cde}`} className="p-2 border rounded mr-2 mb-2">
                      {item.materials.map(material => (
                        <div
                          key={`material-${material.id}`}
                          className={classNames('clickable', {
                            'text-underline': material.default,
                          })}
                          onClick={() => item.selectDefaultMaterial(material.cde)}
                        >
                          {material.label}{' '}
                          {material.default && <i className="fa fa-check text-success" />}
                        </div>
                      ))}
                    </div>
                  ))}
                </React.Fragment>
              )}
            </div>
          )}
        </div>
      )}

      {show && (
        <Button
          className="btn btn-primary"
          onClick={() => {
            addCovering(mode)
            closeForm()
          }}
          disabled={packages.length === 0}
        >
          {mode === 'new' ? 'Ajouter' : 'Sauvegarder'}
        </Button>
      )}

      {show && (
        <button
          className="ml-2 btn btn-secondary"
          onClick={() => {
            resetCoveringForm()
            closeForm()
          }}
        >
          Fermer
        </button>
      )}
    </div>
  )
}

export default observer(CoveringForm)
