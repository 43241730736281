import { observable, action, runInAction, computed, decorate } from 'mobx'
import { isEmpty, isNil, path } from 'ramda'
import moment from 'moment'
import { format } from 'date-fns'

import { checkPrice, furnitureDataTransformer, loadObsolescenceRate } from 'services/calculation'
import UserStore from 'stores/Common/domain/UserStore'
import FurnitureCatalog from 'stores/Mission/domain/FurnitureCatalog'
import MissionStore from 'stores/Mission/domain/MissionStore'
import ManagerClaimStore from 'stores/ManagerClaim/domain/ManagerClaimStore'

class AddFurnitureCtrl {
  furniture = null
  context = 'new'
  showObsolescenceCalculator = false
  checkingPrice = false
  showModalReplacementProducts = false
  replacementProducts = []
  obsolescenceRates = {}
  obsolescenceRatesAllContract = {}

  loadObsolescenceRate = async (customerId, gta, formulaContractKey) => {
    try {
      let data = null
      const obsolescenceRates = {}
      const obsolescenceRatesAllContract = {}

      data = await loadObsolescenceRate(customerId, gta, [formulaContractKey, 'ALL_CONTRACT'])

      if (data) {
        data.forEach(customerObsRate => {
          const categoryRates = {}
          customerObsRate.rates.forEach(
            ({ year, rate }) => (categoryRates[`year${year}`] = rate * 100),
          )
          switch (customerObsRate.formulaContractKey) {
            case formulaContractKey:
              obsolescenceRates[customerObsRate.categoryKey] = categoryRates
              break
            case 'ALL_CONTRACT':
              obsolescenceRatesAllContract[customerObsRate.categoryKey] = categoryRates
              break
            default:
              console.log(`unknown formulaContract ${customerObsRate.formulaContractKey}`)
          }
        })
      }
      this.setProperty('obsolescenceRates', obsolescenceRates)
      this.setProperty('obsolescenceRatesAllContract', obsolescenceRatesAllContract)
    } catch (err) {
      console.log(err)
    }
  }

  checkObsByDateAndCategory(dateOfPurchase, category) {
    let dateOfLoss = UserStore.isExpert ? MissionStore.dateOfLoss : ManagerClaimStore.dateOfLoss
    if (isNil(dateOfLoss) || isEmpty(dateOfLoss)) {
      dateOfLoss = new Date()
    }

    let yearOld = Math.ceil(moment(dateOfLoss).diff(moment(dateOfPurchase), 'years', true))
    if (yearOld > 9) yearOld = 10

    const pathIndex = [category, `year${yearOld}`]

    console.log({ obsolescenceRates: this.obsolescenceRates })

    return path(pathIndex, this.obsolescenceRates) !== undefined
      ? path(pathIndex, this.obsolescenceRates)
      : path(pathIndex, this.obsolescenceRatesAllContract)
  }

  checkObs = () => {
    const { dateOfPurchased, category } = this.furniture
    if (!dateOfPurchased || !category) return

    const obs = this.checkObsByDateAndCategory(dateOfPurchased, category)
    if (!isNil(obs)) {
      this.furniture.setProperty('correction', obs)
      this.furniture.setProperty('obsRateFoundInDatabase', true)
    } else {
      this.furniture.setProperty('obsRateFoundInDatabase', false)
    }
  }

  get checkPriceEnabled() {
    return (
      !this.checkingPrice &&
      this.furniture &&
      this.furniture.price !== 0 &&
      this.furniture.type === 'catalog' &&
      (this.furniture.dateOfPurchased ||
        (this.furniture.parentProduct && this.furniture.parentProduct.dateOfPurchased))
    )
  }

  setFurniture(furniture, context) {
    if (furniture.description === '' && (furniture.brand !== '' || furniture.model !== '')) {
      furniture.description = furniture.model
    }
    this.furniture = furniture
    this.context = context
  }

  setReplacementProduct(product) {
    product.setParentProduct(this.furniture)
    product.setProperty('dateOfPurchased', this.furniture.dateOfPurchased)
    product.setProperty('description', product.model)
    product.setProperty('category', this.furniture.category)
    product.setProperty('involvedParty', this.furniture.involvedParty)

    this.furniture = product
    this.showModalReplacementProducts = false
    this.checkObs()
  }

  setProperty(key, value) {
    this[key] = value
  }

  checkPrice = async () => {
    this.checkingPrice = true
    try {
      const furniture = await checkPrice({
        wan: UserStore.isExpert ? MissionStore.id : ManagerClaimStore.id,
        productId: this.furniture.productId,
        productGroupId: this.furniture.groupId,
        purchasePrice: this.furniture.purchasePrice || this.furniture.price,
        dateOfPurchase: format(this.furniture.dateOfPurchased, 'y-MM-dd'),
        type: this.furniture.productType,
      })
      runInAction(() => {
        this.checkingPrice = false
        // Not commercialised anymore, have replacement product
        if (furniture.replacementProducts && furniture.replacementProducts.length) {
          this.showModalReplacementProducts = true
          this.replacementProducts = furniture.replacementProducts.map(
            (replacementProduct, index) => {
              let furnitureCatalog = new FurnitureCatalog(
                furnitureDataTransformer(replacementProduct),
              )
              if (!isNil(furniture.storeProducts[index])) {
                furnitureCatalog.setProperty('storeProducts', [furniture.storeProducts[index]])
              }

              return furnitureCatalog
            },
          )
        } else {
          console.log('priceWithVat', furniture.price)
          this.furniture.setProperty('priceWithVat', furniture.price)
          this.furniture.setProperty('storeProducts', furniture.storeProducts)
          this.checkObs()
        }
      })
    } catch (err) {
      runInAction(() => {
        this.checkingPrice = false
      })
    }
  }
}

const DecoratedAddFurnitureCtrl = decorate(AddFurnitureCtrl, {
  furniture: observable,
  context: observable,
  showObsolescenceCalculator: observable,
  checkingPrice: observable,
  showModalReplacementProducts: observable,
  replacementProducts: observable,
  obsolescenceRates: observable,
  obsolescenceRatesAllContract: observable,

  loadObsolescenceRate: action,
  setFurniture: action,
  setReplacementProduct: action.bound,
  setProperty: action,
  checkPrice: action,

  checkPriceEnabled: computed,
})

export default new DecoratedAddFurnitureCtrl()
