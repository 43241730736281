import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { configure } from 'mobx'
import { Provider } from 'mobx-react'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import ConfirmBox from 'components/ConfirmBox/ConfirmBox'
import 'font-awesome/css/font-awesome.css'
// import MobileDetect from 'mobile-detect'
import 'moment/locale/fr'
import moment from 'moment'

import { getLocale, setDataLocal } from 'stores/localStorage'
import en from 'i18n/en.json'
import fr from 'i18n/fr.json'
import nl from 'i18n/nl.json'
import './index.css'
import App from './App'
import LoginTransparent from './LoginTransparent'
import ShortenUrl from './ShortenUrl'
// import registerServiceWorker from './registerServiceWorker'
import { unregister } from './registerServiceWorker'
import Stores from 'stores'
import { getTheme } from 'utils'

const theme = getTheme()
if (theme) document.body.classList.add(theme)

moment.locale('fr')

// const md = new MobileDetect(window.navigator.userAgent)
// if (md.mobile()) {
//   document.body.classList.add('mobile')
//   if (md.is('iPhone')) {
//     document.write('<style type="text/css">html {font-size:16px;}</style>')
//   }
// } else {
//   document.body.classList.add('desktop')
// }

// Use strict for mobx
configure({ enforceActions: 'always' })

// init i18n
const locale = getLocale()
if (!locale) setDataLocal('locale', 'fr-FR')
i18n.use(initReactI18next).init({
  lng: locale || 'fr-FR',
  defaultNS: 'translations',
  resources: {
    'fr-FR': { translations: fr },
    'en-US': { translations: en },
    'nl-NL': { translations: nl },
  },
  debug: false,
  react: {
    wait: true,
  },
})

// init ConfirnBox
const getUserConfirmation = (message, callback) => {
  ReactDOM.render(
    <ConfirmBox message={message} callback={callback} />,
    document.getElementById('confirm-box'),
  )
}

ReactDOM.render(
  <Provider {...Stores}>
    <BrowserRouter getUserConfirmation={getUserConfirmation}>
      <Switch>
        <Route path="/loginTransparent/:customerId/:token/:refreshToken/:expiresIn/:userMode?/*">
          <LoginTransparent />
        </Route>
        <Route path="/sh/:shortenToken">
          <ShortenUrl />
        </Route>
        <Route path="/">
          <App />
        </Route>
      </Switch>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root'),
)

// registerServiceWorker()
unregister()
