import { observable, computed, action, reaction, decorate } from 'mobx'
import { isNil, path } from 'ramda'

import CartStore from 'stores/Mission/domain/CartStore'
import Supply from 'stores/Mission/domain/Supply'
import Material from 'stores/Mission/domain/Material'
import Tool from 'stores/Mission/domain/Tool'
import { roundDecimal } from 'utils/currency'

class Item {
  piActive = false
  materials = []
  areaSquare = 1
  qualityType = 'medium'
  selfRepair = false
  totalPrice = null
  overrideTotalPrice = null
  overrideQualificationPrice = null
  overrode = false
  overrodeQualification = false

  cde = null
  order = null
  type = ''
  itemCraft = ''
  qtUnitMinute = null
  qualification = null
  unit = ''
  serviceGroup = ''
  tools = []
  supplies = []
  prices = null
  packageUnit = null
  packageDifficulty = 0
  piGroup = 0
  changeOverrideTotalPriceHandler = null
  changeOverrodeHandler = null

  get totalPriceSupplies() {
    let total = 0
    this.supplies.forEach(supply => {
      if (supply.overridePrice !== null) total += supply.overridePrice
      else total += supply.calculatedPriceWithMargin
    })
    return total
  }

  get totalPriceTools() {
    let total = 0
    this.tools.forEach(tool => {
      if (tool.overridePrice !== null) total += tool.overridePrice
      else total += tool.calculatedPriceWithMargin
    })
    return total
  }

  get totalPriceMaterials() {
    if (this.materials.length === 0) return 0
    const material = this.materials.find(material => material.default)

    if (!material) return 0
    else if (material.overridePrice !== null) return material.overridePrice

    switch (this.qualityType) {
      case 'low':
        return material.calculatedPriceLWithMargin
      case 'medium':
        return material.calculatedPriceMWithMargin
      case 'high':
        return material.calculatedPriceHWithMargin
      default:
        console.warn(`Quality type do not exist ${this.qualityType}`)
        return 0
    }
  }

  get totalPriceCatalog() {
    if (this.overrideTotalPrice !== null) return this.overrideTotalPrice
    if (this.packageUnit === this.unit && (this.unit === 'M2' || this.unit === 'ML'))
      return roundDecimal(this.totalPriceCatalogWithoutArea * this.areaSquare)

    return roundDecimal(this.totalPriceCatalogWithoutArea)
  }

  get totalPriceSelfRepair() {
    if (this.overrideTotalPrice !== null) return this.overrideTotalPrice
    if (this.packageUnit === this.unit && (this.unit === 'M2' || this.unit === 'ML'))
      return roundDecimal(this.totalPriceSelfRepairWithoutArea * this.areaSquare)

    return roundDecimal(this.totalPriceSelfRepairWithoutArea)
  }

  get totalPriceCatalogWithoutArea() {
    let total = this.totalPriceTools + this.totalPriceSupplies + this.totalPriceMaterials
    total += this.overrideQualificationPrice ? this.overrideQualificationPrice : this.priceCatalog
    return roundDecimal(total)
  }

  get totalPriceSelfRepairWithoutArea() {
    const totalPriceSuppliesWithVAT = this.totalPriceSupplies * 1.2
    const totalPriceMaterialsWithVAT = this.totalPriceMaterials * 1.2

    let total = this.totalPriceTools + totalPriceSuppliesWithVAT + totalPriceMaterialsWithVAT

    total += this.overrideQualificationPrice
      ? this.overrideQualificationPrice
      : this.priceSelfRepair

    return roundDecimal(total)
  }

  get priceCatalog() {
    const qualification = CartStore.findQualification(this.qualification.cde)

    return roundDecimal((this.qtUnitMinute / 60) * qualification.hourPrice)
  }

  get priceSelfRepair() {
    const difficultyRate = CartStore.findDifficultyRate(this.packageDifficulty)

    return roundDecimal(
      (Math.round(this.qtUnitMinute * difficultyRate) / 60) *
        CartStore.configuration.selfRepairRate,
    )
  }

  get qualificationPrice() {
    if (this.overrideQualificationPrice) return this.overrideQualificationPrice
    if (this.selfRepair) return this.priceSelfRepair
    return this.priceCatalog
  }

  get qualificationMinPrice() {
    return path(['minPrice'], this.qualification) || 0
  }

  get qtUnit() {
    if (this.selfRepair) {
      const difficultyRate = CartStore.findDifficultyRate(this.packageDifficulty)
      return Math.round(this.qtUnitMinute * difficultyRate)
    }

    return this.qtUnitMinute
  }

  get isItemOverrode() {
    if (this.overrideTotalPrice !== null) return true
    let overrode = false
    this.supplies.forEach(supply => {
      if (supply.overridePrice !== null) overrode = true
    })
    this.tools.forEach(tool => {
      if (tool.overridePrice !== null) overrode = true
    })
    return overrode
  }

  get emptyItem() {
    return this.materials.length > 0 || this.supplies.length > 0 || this.tools.length > 0
  }

  get asJson() {
    return {
      cde: this.cde,
      desc: this.desc,
      itemCraft: this.itemCraft,
      piActive: this.piActive,
      piGroup: this.piGroup,
      qtUnitMinute: this.qtUnitMinute,
      prices: this.prices,
      qualification: this.qualification,
      serviceGroup: this.serviceGroup,
      order: this.order,
      type: this.type,
      unit: this.unit,
      packageUnit: this.packageUnit,
      areaSquare: this.areaSquare,
      materials: this.materials.map(material => material.asJson),
      tools: this.tools.map(tool => tool.asJson),
      supplies: this.supplies.map(supply => supply.asJson),
      qualityType: this.qualityType,
      totalPrice: this.selfRepair ? this.totalPriceSelfRepair : this.totalPriceCatalog,
      overrideTotalPrice: this.overrideTotalPrice,
      overrideQualificationPrice: this.overrideQualificationPrice,
      overrode: this.overrode,
      overrodeQualification: this.overrodeQualification,
    }
  }

  constructor({
    data,
    qualityType,
    packageUnit,
    packageDifficulty,
    areaSquare,
    packageIsSelfRepair,
  }) {
    this.cde = data.cde
    this.desc = data.desc
    this.order = !isNil(data.order) ? data.order : null
    this.itemCraft = data.itemCraft
    this.piActive = data.pi_active ? data.pi_active : data.piActive || false
    this.piGroup = !isNil(data.pi_group) ? data.pi_group : data.piGroup || 0
    this.qtUnitMinute = data.qtUnitMinute
    this.prices = data.prices
    this.qualification = data.qualification
    this.serviceGroup = data.serviceGroup
    this.type = data.type.cde ? data.type.cde : data.type
    this.unit = data.unit
    this.packageUnit = packageUnit
    this.packageDifficulty = packageDifficulty
    this.areaSquare = areaSquare
    this.selfRepair = packageIsSelfRepair
    this.materials = data.materials.map(material => new Material(material))
    this.tools = data.tools.map(tool => new Tool(tool))
    this.supplies = data.supplies.map(supply => new Supply(supply))
    this.qualityType = qualityType
    this.overrideTotalPrice = data.overrideTotalPrice || null
    this.overrideQualificationPrice = data.overrideQualificationPrice || null
    this.overrode = data.overrode || false
    this.overrodeQualification = data.overrodeQualification || false

    this.changeOverrideTotalPriceHandler = reaction(
      () => this.overrideTotalPrice,
      overrideTotalPrice => {
        if (overrideTotalPrice !== null) {
          this.overrodeQualification = true
          this.supplies.forEach(supply => supply.setProperty('overrode', true))
          this.materials.forEach(material => material.setProperty('overrode', true))
          this.tools.forEach(tool => tool.setProperty('overrode', true))
        } else {
          this.overrodeQualification = false
          this.supplies.forEach(supply => supply.setProperty('overrode', false))
          this.materials.forEach(material => material.setProperty('overrode', false))
          this.tools.forEach(tool => tool.setProperty('overrode', false))
        }
      },
    )
    this.changeOverrodeHandler = reaction(
      () => this.overrode,
      overrode => {
        this.supplies.forEach(supply => supply.setProperty('overrode', overrode))
        this.materials.forEach(material => material.setProperty('overrode', overrode))
        this.tools.forEach(tool => tool.setProperty('overrode', overrode))
      },
    )
  }

  setProperty(key, value) {
    this[key] = value
  }

  selectDefaultMaterial(cde) {
    this.materials = this.materials.map(material => {
      material.default = false
      if (material.cde === cde) material.default = true
      return material
    })
  }
}

const DecoratedItem = decorate(Item, {
  piActive: observable,
  materials: observable,
  areaSquare: observable,
  qualityType: observable,
  selfRepair: observable,
  overrideTotalPrice: observable,
  overrideQualificationPrice: observable,
  overrode: observable,
  overrodeQualification: observable,

  setProperty: action,
  selectDefaultMaterial: action,

  totalPriceSupplies: computed,
  totalPriceTools: computed,
  totalPriceMaterials: computed,
  totalPriceCatalog: computed,
  totalPriceSelfRepair: computed,
  totalPriceCatalogWithoutArea: computed,
  totalPriceSelfRepairWithoutArea: computed,
  priceCatalog: computed,
  qualificationMinPrice: computed,
  priceSelfRepair: computed,
  qualificationPrice: computed,
  qtUnit: computed,
  isItemOverrode: computed,
  emptyItem: computed,
  asJson: computed,
})

export default DecoratedItem
